<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div>
  <sdps-notification type="error" class="sdps-m-top_none"
    *ngIf="(!errorType && reportOptionalFieldsError) || isInValidFromDate || isInValidToDate || isPastFromDate || isPastToDate || isFutureFromDate || isFutureToDate || schErrorMsg.length > 0 || endDateLessThanStartDate || dateRangeConstraint || reportService.errorInAccntHolderLastName || reportService.errorInEmpLastName || reportService.invalidCusip || reportService.invalidSymbol">
    <div class="sdps-notification__text">
      <p>Please correct following error.</p>
      <ul class="sdps-navigation--horizontal__list error-list">
        <li *ngIf="(!errorType && reportOptionalFieldsError)">
          <p *ngFor="let item of reportOptionalFieldsError; let i = index">
            <span [innerHTML]="getConstant.reports.presetReports.badRequest +' '+ item.defaultMessage"></span>
          </p>
        </li>
        <li *ngIf="isInValidFromDate || isInValidToDate">
          <span [innerHTML]="getConstant.invalidDate"></span>
        </li>
        <li *ngIf="isFutureFromDate || isFutureToDate">
          <span [innerHTML]="getConstant.invalidFutureDateEntered"></span>
        </li>
        <li *ngIf="isPastFromDate || isPastToDate">
          <span [innerHTML]="pastDateErrorMessage"></span>
        </li>
        <li *ngIf="endDateLessThanStartDate">
          <span [innerHTML]="endDateLessThanStartDate"></span>
        </li>
        <li *ngIf="dateRangeConstraint">
          <span [innerHTML]="dateRangeConstraint"></span>
        </li>
        <li *ngFor='let msg of schErrorMsg' [innerHTML]="msg"></li>
        <li *ngIf="reportService.errorInEmpLastName || reportService.errorInAccntHolderLastName" [innerHTML]="getConstant.manageClients.clientDetailsPage.errorMsg.invalidCharacterEnteredAlphabeticValue"></li>
        <li *ngIf="reportService.invalidSymbol || reportService.invalidCusip" [innerHTML]="sharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName"></li>
      </ul>
    </div>
  </sdps-notification>
  <ems-toast-message #toastMessage></ems-toast-message>

  <h2 class="page-title sdps-p-bottom_small sdps-display-inline-block"
      [innerHTML]="getConstant.reports.presetReports.presetReportsLabel"></h2>

  <span class="sdps-m-horizontal_large sdps-m-bottom_medium sdps-display-inline-block client-selection-indicator"
        [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
        [innerHTML]="(addlinkService.clientInformation | async)?.k4ClientName">
</span>

  <div class="preset-report">

    <div [ngClass]="{'table-record':expandClicked}">
      <!-- Adding Refresh Button in Reports Landing Page #20520 -->

      <ems-table-record-count *ngIf="!expandClicked" [count]="totalRecords" [showHeaderIcons]="false"
                              (emitter)="pageObject.pageSize = $event;getReportsList();"
                              [showTotal]='totalRecords' [showRecords]="totalRecords && totalRecords > 15"></ems-table-record-count>
    </div>

    <div class="report-list-report-details" [ngClass]="{'report-list-display':expandClicked}">
      <div class="sdps-p-top_large sdps-is-relative report-list"
           [ngClass]="{'report-list-compressed':expandClicked}">

        <div [ngClass]="{'report-list-compressed-table':expandClicked}">
          <p-table [columns]="reportListHeaders" [value]="reportsList" [customSort]="true" dataKey="id" #reports
                   (onFilter)="pageObject.filterField = $event.field;" class="common-data-table auth-agent-table-layout-fixed">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [innerHTML]="col.header">
                  <em class="pi pi-filter"></em>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">

                <ng-container *ngFor="let col of columns">
                  <td *ngIf="getOrgId === 0">
                    <span *ngIf="col.field==='name'" class="report-name-link cursor"
                          (click)="selectedReport = rowData[col.field]; reportFormIsDirty ? changeReportConfirmation('loadReport') : loadSelectedReports(rowData[col.field])"
                          [ngClass]="{'active-report-selected':reportName ===  rowData[col.field],'default-cursor':rowData[col.field] === 'Employee/Employee Group Association SnapShot'}"
                          [innerHTML]="rowData[col.field]"></span>
                    <span
                      *ngIf="col.field!=='name' && !expandClicked"
                      [innerHTML]="rowData[col.field]"></span>
                  </td>
                  <td *ngIf="getOrgId !== 0">
                    <span *ngIf="col.field==='name'" class="report-name-link cursor"
                        (click)="selectedReport = rowData[col.field]; reportFormIsDirty ? changeReportConfirmation('loadReport') : loadSelectedReports(rowData[col.field])"
                        [ngClass]="{'active-report-selected':reportName ===  rowData[col.field],'default-cursor':rowData[col.field] === 'Employee/Employee Group Association SnapShot'}"
                        [innerHTML]="rowData[col.field]"></span>
                    <span
                    *ngIf="col.field!=='name' && !expandClicked"
                    [innerHTML]="rowData[col.field]"></span>
                  </td>
                </ng-container>
              </tr>
            </ng-template>
          </p-table>

          <p-paginator [alwaysShow]="paginationCount && paginationCount > 15 ? true : false" [rows]="pageObject.pageSize" [totalRecords]="paginationCount"
                       (onPageChange)="pageObject.pageNo = $event.page;getReportsList();"
                       class="common-pagination"></p-paginator>
        </div>

        <div class="sdps-display-inline-block" *ngIf="expandClicked" [ngClass]="{'report-details':expandClicked}">
          <em class="pi expand-report pi-angle-double-right" (click)="reportFormIsDirty ? changeReportConfirmation('expandReport') : expandReportDetailsSection()"></em>
        </div>
      </div>

      <div *ngIf="expandClicked" [ngClass]="{'report-details-expand':expandClicked}">
        <div class="sdps-grid-container sdps-wrap  sdps-p-horizontal_medium sdps-p-top_large">
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
